import React from 'react';
import ReactDOM from 'react-dom';

// make it possible to add PB to window.
declare global {
  interface Window { PB: PB }
}

/*
  Crappy catch-all class to hold everything else that doesn't go neatly into something.
 */
class PB {
  init() {
    console.log(`%c

     _|_|_|                                  _|            _|_|_|
     _|    _|  _|    _|  _|_|_|_|  _|_|_|_|  _|    _|_|    _|    _|    _|_|_|  _|_|_|      _|_|_|
     _|_|_|    _|    _|      _|        _|    _|  _|_|_|_|  _|_|_|    _|    _|  _|    _|  _|    _|
     _|        _|    _|    _|        _|      _|  _|        _|    _|  _|    _|  _|    _|  _|    _|
     _|          _|_|_|  _|_|_|_|  _|_|_|_|  _|    _|_|_|  _|_|_|      _|_|_|  _|    _|    _|_|_|
                                                                                               _|
                                                                                           _|_|    `, "font-family:monospace");

    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    });

    window.setTimeout(this.spinTitle, 3000);
  }

  logout() {
    $.ajax({
      type: "DELETE",
      url: "/logout_ajax",
      dataType: "json",
      success: (data, textStatus, jqXHR) => {
        console.log("Logged out");
        window.location.href = "/login";
      },
      error: (jqXHR, textStatus, errorThrown) => {
        console.error("Could not log out?", jqXHR, textStatus, errorThrown);
      }
    });
  }

  googleLogin(response) {
    // decodeJwtResponse() is a custom function defined by you
    // to decode the credential response.

    // jwtDecrypt(response.credential, "").then((responsePayload) => {
    //   console.log("ID: " + responsePayload.sub);
    //   console.log('Full Name: ' + responsePayload.name);
    //   console.log('Given Name: ' + responsePayload.given_name);
    //   console.log('Family Name: ' + responsePayload.family_name);
    //   console.log("Image URL: " + responsePayload.picture);
    //   console.log("Email: " + responsePayload.email);
    // });

  }

  spinTitle() {
    const $title = $(".title");
    const target = 'Past Bureaucratic Authority';
    const start = $title.text().trim();
    const replacement = [];
    for (let i=0; i<target.length; i++) {
      const char = start[i] || "&nbsp;";
      replacement.push(`<span id='title-${i}'>${char}</span>`);
    }
    $title.html(replacement.join(""));
    const randLetter = () => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz ';
      return characters.charAt(Math.floor(Math.random() * characters.length));
    };

    let i = 0;
    let j = 1;
    const spinTimer = setInterval(() => {
      if ($("#title-"+i)[0] && $("#title-"+i).text() !== target[i]) {
        $("#title-"+i).text(randLetter());
      } else {
        i += 1;
        if (i === j) {
          i += 1;
        }
      }
      if ($("#title-"+j)[0] && $("#title-"+j).text() !== target[j]) {
        $("#title-"+j).text(randLetter());
      } else {
        j += 1;
        if (i === j) {
          j += 1;
        }
      }
      if (i >= 20 || j >= 20) {
        $(".logo").addClass("logo--b-side")
      }
      if (i > replacement.length && j > replacement.length) {
        clearInterval(spinTimer);
      }
    }, 10);
  }
}

if (!window.PB){
  window.PB = new PB;
}

export default window.PB;
