/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import { MDCDrawer } from "@material/drawer";
import PB from '../2021'

function docReady(fn) {
  // see if DOM is already available
  if (document.readyState === "complete" || document.readyState === "interactive") {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

// This is effectively the entry point to things.
docReady(() => {
  // Set up navigation drawer
  const drawerElement = document.querySelector('.mdc-drawer--modal');
  const drawer = MDCDrawer.attachTo(document.querySelector('.mdc-drawer'));

  // Set up menuToggle
  var menuToggle = document.querySelector(".js-menu");
  // On click
  menuToggle.addEventListener("click", () => {
    menuToggle.classList.add("is-active");
    // Do something else, like open/close menu
    drawer.open = !drawer.open;
  });

  // Hook up the drawer events to the menuToggle class display
  drawerElement.addEventListener("MDCDrawer:opened", () => {
    // menuToggle.classList.add("is-active");
  });
  drawerElement.addEventListener("MDCDrawer:closed", () => {
    menuToggle.classList.remove("is-active");
  });

  PB.init();
});
